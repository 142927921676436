import axios, { AxiosPromise } from 'axios';
import {
  getServiceAreaId,
  ServiceAreas
} from '../../components/layout/landing/service-area-blocks/ServiceArea.helpers';
import { ModuleType } from '../../models/enums.bl';
import * as Models from '../../shared.models';
import Services from '../../shared.services';
import ConfigService from './config.service';
import { CaseTypeDataDto, SelfAssessCountryDTO, SelfAssessTypeDTO } from '../../interface';

const separator = '/';

enum ApiEntity {
  Log = 'log',
  Lookup = 'lookup',
  Case = 'case',
  CaseDetail = 'caseDetail',
  CaseData = 'casedata',
  Edit = 'edit',
  Registration = 'registration',
  User = 'user',
  Locales = 'localization',
  Cases = 'cases',
  Client = 'client',
  WorkFlow = 'workflow',
  Questionnaire = 'Questionnaire',
  ScoreCard = 'scorecard',
  Financial = 'financial',
  Illustrative = 'illustrative',
  Report = 'report',
  IPOroadmap = 'roadmap',
  CaseType = 'casetype',
  Countries = 'casetype',
  Dashboard = 'dashboard',
  MultiGeo = 'multigeo',
  CaseVersion1 = 'v1',
  RoadmapTask = 'RoadmapTask',
  Module = 'module',
  Templates = 'templates',
  Deleted = 'deleted',
  Memberfirm = 'memberfirm',
  Admin = 'admin',
  SummaryReport = 'summaryreport',
  SAPReport = 'sapreport',
  IPOReadiness = 'ipoReadiness',
  IPOReadinessReport = 'ipoReadinessReport',
  IPOReadinessFeedback = 'ipoReadinessFeedback',
  SELF_ASSESS_GENERAL_INFO = 'selfAssessGeneralInfo',
  SELF_ASSESS = 'selfAssess'
}

export const API_SUB_ENTITY_FOR_EXPORT_CASE_TYPE_MODULE_QUESTIONNAIRE: Record<ModuleType, ApiEntity | ''> = {
  [ModuleType.SCORECARD]: ApiEntity.ScoreCard,
  [ModuleType.SUMMARY]: ApiEntity.SummaryReport,
  [ModuleType.ROADMAP]: ApiEntity.IPOroadmap,
  [ModuleType.SAP]: ApiEntity.SAPReport,
  [ModuleType.FGA]: '',
  [ModuleType.TEMPLATE]: '',
  [ModuleType.FMM]: '',
  [ModuleType.IFI]: '',
  [ModuleType.PROPOSAL]: '',
  [ModuleType.IPO_SCANNER]: ApiEntity.IPOReadiness,
  [ModuleType.SELF_ASSESS_GENERAL_INFO]: ApiEntity.SELF_ASSESS_GENERAL_INFO
};

export default class ApiUrlsService {
  static get DefaultRegionUrl() {
    return Services.BL.ConfigService.ApiUrls[Services.BL.ConfigService.DefaultRegion];
  }

  static get GeoRelatedUrl() {
    return Services.BL.ConfigService.ApiUrls[Services.BL.ConfigService.GeoRelatedRegion];
  }

  static get MainRegionUrl() {
    // INFO: always EMA
    return Services.BL.ConfigService.ApiUrls[Services.BL.ConfigService.MainRegion];
  }

  static exportToExcel(regionName: string): string {
    return [this.getDomainUrl(regionName), ApiEntity.Registration, 'exportToExcel'].join(separator);
  }

  static logError(): string {
    return [this.DefaultRegionUrl, ApiEntity.Log, 'error'].join(separator);
  }

  static registrationSave(regionName?: string): string {
    return [this.getDomainUrl(regionName), ApiEntity.Registration, 'save'].join(separator);
  }

  static registrationSaveUrls(): Array<string> {
    return Object.values(Services.BL.ConfigService.ApiUrls).map((url: string) =>
      [url, ApiEntity.Registration, 'save'].join(separator)
    );
  }

  static getUrlsUserProfile() {
    // INFO: need to set default url at the end - fetch user profile starts from the end of array
    // so fetch will start from default url
    return Object.entries(Services.BL.ConfigService.ApiUrls)
      .sort((a, b) => {
        if (a[0] === Services.BL.ConfigService.DefaultRegion) return 1;
        if (b[0] === Services.BL.ConfigService.DefaultRegion) return -1;
        return 0;
      })
      .reduce<{ urls: string[]; geoCodes: string[] }>(
        (acc, [geoCode, url]) => {
          acc.urls.push([url, ApiEntity.User, 'profile'].join(separator));
          acc.geoCodes.push(geoCode);
          return acc;
        },
        { urls: [], geoCodes: [] }
      );
  }

  static getTermsofUse(): AxiosPromise<Models.DM.TermsofUse> {
    return axios.get([this.DefaultRegionUrl, ApiEntity.User, 'termsofuse'].join(separator));
  }

  static saveUserTersmofUse(termsofUseVersion: Models.DM.TermsofUse): AxiosPromise<Models.DM.UserGetDto> {
    return axios.post([this.DefaultRegionUrl, ApiEntity.User, 'saveusertermsofuse'].join(separator), termsofUseVersion);
  }

  static registrationList(regionName?: string): string {
    return [this.getDomainUrl(regionName), ApiEntity.Registration, 'getGrid'].join(separator);
  }

  static approve(regionName?: string): string {
    return [this.getDomainUrl(regionName), ApiEntity.Registration, 'approve'].join(separator);
  }

  static reject(regionName?: string): string {
    return [this.getDomainUrl(regionName), ApiEntity.Registration, 'reject'].join(separator);
  }

  static cancel(regionName?: string): string {
    return [this.getDomainUrl(regionName), ApiEntity.Registration, 'cancel'].join(separator);
  }

  static getCountries(): string {
    return [this.GeoRelatedUrl, ApiEntity.Countries, 'casetypedata'].join(separator);
  }

  static changeLanguage(): string {
    return [this.GeoRelatedUrl, ApiEntity.Locales, 'localeDictionary'].join(separator);
  }

  static sendEmail(): string {
    return [this.GeoRelatedUrl, ApiEntity.User, 'downloadEmailAlert'].join(separator);
  }

  static logout(): string {
    // INFO: need call EMA url always
    return [this.MainRegionUrl, ApiEntity.User, 'logout'].join(separator);
  }

  static getLocales(): string {
    return [this.GeoRelatedUrl, ApiEntity.Locales, 'locales'].join(separator);
  }

  static saveLocale(): string {
    return [this.GeoRelatedUrl, ApiEntity.Locales, 'saveLocale'].join(separator);
  }

  static getAssetUrl(): string {
    return [this.GeoRelatedUrl, ApiEntity.Locales, 'asset'].join(separator);
  }

  static getCasesUrl(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseVersion1, ApiEntity.Dashboard, ApiEntity.Cases].join(separator);
  }

  static getDeletedCasesUrl(): string {
    return [this.GeoRelatedUrl, ApiEntity.Dashboard, ApiEntity.Cases, ApiEntity.Deleted].join(separator);
  }

  static getDeletedCaseTypeUrl(memberFirmId: number): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Deleted,
      ApiEntity.Cases,
      ApiEntity.CaseType,
      ApiEntity.Memberfirm,
      memberFirmId,
      'caseCategory',
      getServiceAreaId()
    ].join(separator);
  }

  static getDeletedClientName(memberFirmId: number): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Deleted,
      ApiEntity.Cases,
      ApiEntity.Client,
      ApiEntity.Memberfirm,
      memberFirmId,
      'caseCategory',
      getServiceAreaId()
    ].join(separator);
  }

  static getDeletedCaseName(memberFirmId: number): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Deleted,
      ApiEntity.Cases,
      ApiEntity.Case,
      ApiEntity.Memberfirm,
      memberFirmId,
      'caseCategory',
      getServiceAreaId()
    ].join(separator);
  }

  static getCaseTypeData(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType].join(separator);
  }

  static updateDisclaimer(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'policy', 'save'].join(separator);
  }

  static getDisclaimer(caseTypeId: string | number): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, caseTypeId, 'policy'].join(separator);
  }

  static getCaseTypesUrl(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, ApiEntity.Dashboard].join(separator);
  }

  static getCaseTypesList(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, ApiEntity.Dashboard, 'caseTypes'].join(separator);
  }

  static getCaseTypes(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'casetypedata'].join(separator);
  }

  static getCaseTypesByCountryId(countryId: string | number, serviceArea: ServiceAreas): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'casetypedata', countryId, serviceArea].join(separator);
  }

  static getCaseName(): string {
    return [this.GeoRelatedUrl, ApiEntity.Case].join(separator);
  }

  static getCaseType(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType].join(separator);
  }

  static getStatusUpdate(): string {
    return [this.GeoRelatedUrl, ApiEntity.Case].join(separator);
  }

  static getClientName(): string {
    return [this.GeoRelatedUrl, ApiEntity.Client].join(separator);
  }

  static getCaseDetails(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseDetail].join(separator);
  }

  static getEditCaseDetails(): string {
    return [this.GeoRelatedUrl, ApiEntity.Case, ApiEntity.Edit].join(separator);
  }

  private static getDomainUrl(regionName?: string): string {
    return Services.BL.ConfigService.ApiUrls[regionName || ConfigService.DefaultRegion];
  }

  static getMultiGeoEnvironments(): string {
    return [this.DefaultRegionUrl, ApiEntity.MultiGeo, 'environment'].join(separator);
  }

  static getMultiGeoDetails(): string {
    return [this.DefaultRegionUrl, ApiEntity.MultiGeo, 'details'].join(separator);
  }

  static getMemberFirmsDataByMultiGeo() {
    // INFO: start requests from default
    return Object.entries(Services.BL.ConfigService.ApiUrls)
      .sort((a, b) => {
        if (a[0] === Services.BL.ConfigService.DefaultRegion) return -1;
        if (b[0] === Services.BL.ConfigService.DefaultRegion) return 1;
        return 0;
      })
      .reduce<{ urls: string[]; geoCodes: string[] }>(
        (acc, [geoCode, url]) => {
          acc.urls.push([url, ApiEntity.Dashboard, 'data'].join(separator));
          acc.geoCodes.push(geoCode);
          return acc;
        },
        { urls: [], geoCodes: [] }
      );
  }

  static getCaseData(): string {
    return [this.GeoRelatedUrl, ApiEntity.Case, 'data'].join(separator);
  }

  static saveCaseDetail(): string {
    return [this.GeoRelatedUrl, ApiEntity.Case].join(separator);
  }

  static availableCaseTypeDetail(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'availablecasetypes'].join(separator);
  }

  static getcaseType(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'casetype'].join(separator);
  }

  static createCaseType(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'createcasetype'].join(separator);
  }

  static updateCaseType(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'updatecasetype'].join(separator);
  }

  static getWorkFlow(): string {
    return [this.GeoRelatedUrl, ApiEntity.WorkFlow].join(separator);
  }

  static saveWorkFlowQA(): string {
    return [this.GeoRelatedUrl, ApiEntity.WorkFlow, 'question'].join(separator);
  }

  static saveWorkFlowNote(): string {
    return [this.GeoRelatedUrl, ApiEntity.WorkFlow, 'note'].join(separator);
  }

  // static getUserRoles(): string {
  //     return [this.GeoRelatedUrl, ApiEntity.Lookup, 'GetUserRoles'].join(separator);
  // }

  static getUsers(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseData, 'teamuserlist'].join(separator);
  }

  static getScoreCardReportDetails(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, ApiEntity.ScoreCard].join(separator);
  }

  static getScoreCardReportDetailsOne(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, 'scorecardSnapReport'].join(separator);
  }

  static getSapReportData(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, ApiEntity.SAPReport, 'preview'].join(separator);
  }

  static getFgAnalysis(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, 'fga'].join(separator);
  }

  static getIllustrativeReport(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, 'illustrative'].join(separator);
  }

  static getProposal(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, 'proposal'].join(separator);
  }

  static getIPOroadmapReport(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, 'roadmap'].join(separator);
  }

  static getRoadmapExcel(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, 'roadmap', 'excel'].join(separator);
  }

  static getSAPExcel(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, ApiEntity.SAPReport, 'excel'].join(separator);
  }

  static getSAPPowerPoint(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, ApiEntity.SAPReport, 'powerpoint'].join(separator);
  }

  static getroadmapReport(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, 'roadmapv2'].join(separator);
  }

  static getModuleTypes(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'moduleTypes'].join(separator);
  }

  static saveModuleType(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'saveModule'].join(separator);
  }

  static saveQuestionnaire(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'Questionnaire'].join(separator);
  }

  static saveQuestionaire(): string {
    return [this.GeoRelatedUrl, 'Questionnaire', 'savequestionaire'].join(separator);
  }

  static saveModuleOrder(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'saveModuleOrder'].join(separator);
  }

  static saveModuleScore(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'moduleScore'].join(separator);
  }

  static saveModuleQuestionnaireStepScores(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'questionnaireStepScores'].join(separator);
  }

  static getQuestionnaire(): string {
    return [this.GeoRelatedUrl, ApiEntity.Questionnaire, 'questiondetail'].join(separator);
  }

  static getStepandQuestionnaire(): string {
    return [this.GeoRelatedUrl, 'questionnaire'].join(separator);
  }

  static getModuleScore(): string {
    return [this.GeoRelatedUrl, ApiEntity.Questionnaire, 'moduleScores'].join(separator);
  }

  static saveQuestionnaireQA(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'QuestionnaireAnswer'].join(separator);
  }

  static saveQuestionnaireNote(): string {
    return [this.GeoRelatedUrl, 'case', 'note', 'save'].join(separator);
  }

  static getQuestionnaireNote(): string {
    return [this.GeoRelatedUrl, 'case', 'note', 'data'].join(separator);
  }

  static publishCaseType(id: number): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, id, 'publish'].join(separator);
  }

  static assignContentAdminsToCaseType(id: string): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, id, 'assignContentAdmins'].join(separator);
  }

  static unpublishCaseType(id: string): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, id, 'unpublish'].join(separator);
  }

  static saveRoadmapTask(): string {
    return [this.GeoRelatedUrl, ApiEntity.RoadmapTask, 'task'].join(separator);
  }

  static getRoadmapTasks(): string {
    return [this.GeoRelatedUrl, ApiEntity.RoadmapTask, 'data'].join(separator);
  }

  static removeRoadmapTask(): string {
    return [this.GeoRelatedUrl, ApiEntity.RoadmapTask, 'remove'].join(separator);
  }

  static validateAssociatedTask(): string {
    return [this.GeoRelatedUrl, ApiEntity.RoadmapTask, 'validateTask'].join(separator);
  }

  static getScoreCardReport(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, 'V2', 'scorecardReport'].join(separator);
  }

  static getScoreCardSnapshotReport(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, 'V2', 'scoreCardSnapReport'].join(separator);
  }

  static getQuestionnaireTasks(): string {
    return [this.GeoRelatedUrl, ApiEntity.RoadmapTask, 'moduletasks'].join(separator);
  }

  static saveIPOroadmapReportJson(): string {
    return [this.GeoRelatedUrl, ApiEntity.IPOroadmap, 'SaveRoadMapReportJson'].join(separator);
  }

  static getIPOroadmapReportJson(): string {
    return [this.GeoRelatedUrl, ApiEntity.IPOroadmap, 'GetRoadMapReportJson'].join(separator);
  }

  static getFgAnalysisReport(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, 'V2', 'fga'].join(separator);
  }

  static getIllustrativeReportV2(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, 'V2', 'illustrative'].join(separator);
  }

  static getProposalV2(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, 'V2', 'proposal'].join(separator);
  }

  static removeModuleType(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'removeModule'].join(separator);
  }

  static removeQuestionnaire(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'removeQuestionnaire'].join(separator);
  }

  static renameQuestionnaire(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'Questionnaire'].join(separator);
  }

  static createPractitionerQuestionnaire(caseUID: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Case, caseUID, 'caseQuestionnaire', 'create'].join(separator);
  }

  static removePractitionerQuestionnaire(caseUID: string, questionnaireUid: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Case, caseUID, 'caseQuestionnaire', questionnaireUid].join(separator);
  }

  static renamePractitionerQuestionnaire(caseUID: string, questionnaireUid: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Case, caseUID, 'caseQuestionnaire', questionnaireUid].join(separator);
  }

  static getQuestionnaireVisibility(caseUID: string, moduletyeId: any): string {
    return [this.GeoRelatedUrl, ApiEntity.Case, caseUID, 'module', moduletyeId, 'questionnaireVisbility'].join(
      separator
    );
  }

  static validateQuestionnaireFormulas(): string {
    return [this.GeoRelatedUrl, ApiEntity.Questionnaire, 'validateFormulas'].join(separator);
  }

  static saveQuestionnaireVisibility(caseUID: any): string {
    return [this.GeoRelatedUrl, ApiEntity.Case, caseUID, 'savequestionnaireVisbility'].join(separator);
  }

  static deleteCaseType(caseTypeId: number): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, caseTypeId, 'delete'].join(separator);
  }

  static deleteCase(caseUID: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Case, caseUID, '5'].join(separator);
  }

  static hardDeleteCase(caseUID: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Case, caseUID, 'delete', 'hard'].join(separator);
  }

  static restoreCase(caseUID: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Case, caseUID, 'restore'].join(separator);
  }

  static getCaseTeamMembers(caseUID: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Case, caseUID, 'teammembers'].join(separator);
  }

  static getScoreCardTemplate(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, ApiEntity.ScoreCard, 'download'].join(separator);
  }

  static postCaseTeamMembers(): string {
    return [this.GeoRelatedUrl, 'caseteammembers'].join(separator);
  }

  static getFFMReport(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, 'financematurity'].join(separator);
  }

  static getQuestionnaireScore(): string {
    return [this.GeoRelatedUrl, ApiEntity.Questionnaire, 'questionnaireScores'].join(separator);
  }

  static saveQuestionnaireGlossary(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'questionnaireGlossary'].join(separator);
  }

  static getQuestionTypes(): string {
    return [this.GeoRelatedUrl, ApiEntity.Questionnaire, 'question', 'types'].join(separator);
  }

  static saveQuestionnaireScore(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'questionnaireScores'].join(separator);
  }

  static saveModuleColorConfig(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'modulecolorconfig'].join(separator);
  }

  static getCaseTypeList(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'search'].join(separator);
  }

  static getUpdatedBy(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'usersearch'].join(separator);
  }

  static getCountrySearch(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'countrysearch'].join(separator);
  }

  static getFilesList(moduleUID: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Templates, moduleUID].join(separator);
  }

  static uploadFiles(caseTypeId: number, moduleUID: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Templates, caseTypeId, moduleUID, 'upload'].join(separator);
  }

  static uploadScorecardFiles = (caseTypeId: number, moduleUID: string) => {
    return [
      this.GeoRelatedUrl,
      ApiEntity.CaseType,
      caseTypeId,
      ApiEntity.ScoreCard,
      moduleUID,
      'import',
      'questionnaires'
    ].join(separator);
  };

  static uploadReportFile = (caseTypeId: number, moduleUID: string) => {
    return [
      this.GeoRelatedUrl,
      ApiEntity.CaseType,
      caseTypeId,
      ApiEntity.SummaryReport,
      moduleUID,
      'import',
      'questionnaires'
    ].join(separator);
  };

  static downloadFile(moduleUID: string, fileId: number): string {
    return [this.GeoRelatedUrl, ApiEntity.Templates, moduleUID, fileId].join(separator);
  }

  static downloadWordReport(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, 'V2', 'questionnaireReport'].join(separator);
  }

  static downloadHedgeDocumentationReport(): string {
    return [this.GeoRelatedUrl, ApiEntity.Report, 'summaryReport', 'hedgeDocumentation'].join(separator);
  }

  static deleteFile(caseTypeId: number, moduleUID: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Templates, caseTypeId, moduleUID].join(separator);
  }

  static getRoadmapDrivers(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'roadmap', 'drivers'].join(separator);
  }

  static getRoadmapMaturityLevels(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'roadmap', 'maturity_levels'].join(separator);
  }

  static getRoadmapTemplate(moduleUID: string): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, 'roadmap', moduleUID, 'download'].join(separator);
  }

  static uploadRoadmapFiles = (caseTypeId: number, moduleUID: string) => {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, caseTypeId, 'roadmap', moduleUID, 'import', 'questionnaires'].join(
      separator
    );
  };

  static uploadSapReportFiles = (caseTypeId: number, moduleUID: string) => {
    return [
      this.GeoRelatedUrl,
      ApiEntity.CaseType,
      caseTypeId,
      ApiEntity.SAPReport,
      moduleUID,
      'import',
      'questionnaires'
    ].join(separator);
  };

  static uploadIPOReportFiles = (caseTypeId: number, moduleUID: string) => {
    return [
      this.GeoRelatedUrl,
      ApiEntity.CaseType,
      caseTypeId,
      ApiEntity.IPOReadiness,
      moduleUID,
      'import',
      'questionnaires'
    ].join(separator);
  };

  static uploadSelfAssessGeneralInfoReportFiles = (caseTypeId: number, moduleUID: string) => {
    return [
      this.GeoRelatedUrl,
      ApiEntity.CaseType,
      caseTypeId,
      ApiEntity.SELF_ASSESS_GENERAL_INFO,
      moduleUID,
      'import',
      'questionnaires'
    ].join(separator);
  };

  static getSummaryReportTemplate(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, ApiEntity.SummaryReport, 'download'].join(separator);
  }

  static getSAPReportTemplate(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, ApiEntity.SAPReport, 'download'].join(separator);
  }

  static getIpoReadinessReportTemplate(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, ApiEntity.IPOReadiness, 'download'].join(separator);
  }

  static getSelfAssessGeneralInfoReportTemplate(): string {
    return [this.GeoRelatedUrl, ApiEntity.CaseType, ApiEntity.SELF_ASSESS_GENERAL_INFO, 'download'].join(separator);
  }

  private static get settingsApiURL() {
    return [this.GeoRelatedUrl, ApiEntity.Module, 'settings'].join(separator);
  }

  private static get settingsFGAApiURL() {
    return [this.settingsApiURL, 'fga'].join(separator);
  }

  static getModuleSettingsFiles(): string {
    return [this.settingsFGAApiURL, 'files'].join(separator);
  }

  static getModuleSettingsFileById(fileId: number): string {
    return [this.settingsFGAApiURL, 'file', fileId].join(separator);
  }

  static uploadSettingsFGAFile(): string {
    return [this.settingsFGAApiURL, 'upload'].join(separator);
  }

  static getLastUpdatedFGAInfo(): string {
    return [this.settingsFGAApiURL, 'lastupdatedinfo'].join(separator);
  }

  static getFGAExchanges(): string {
    return [this.settingsFGAApiURL, 'exchanges'].join(separator);
  }

  static postFGAExchanges(): string {
    return [this.settingsFGAApiURL, 'save', 'exchanges'].join(separator);
  }

  private static get settingsDataRetentionPolicyApiURL() {
    return [this.settingsApiURL, 'dataRetentionPolicy'].join(separator);
  }

  static getDataRetentionPolicy(): string {
    return this.settingsDataRetentionPolicyApiURL;
  }

  static updateDataRetentionPolicy(): string {
    return this.settingsDataRetentionPolicyApiURL;
  }

  static getMultiGeoMemberFirms(): string {
    return [this.settingsApiURL, 'memberFirms'].join(separator);
  }

  static getIpoSettings(): string {
    return [this.GeoRelatedUrl, ApiEntity.IPOReadiness, 'settings'].join(separator);
  }

  static downloadIpoGeneralFile(countryCode: string, fileId: number): any {
    return [this.GeoRelatedUrl, ApiEntity.IPOReadiness, countryCode, 'file', fileId].join(separator);
  }

  static getIpoCountries(): string {
    return [this.GeoRelatedUrl, ApiEntity.IPOReadiness, 'countries'].join(separator);
  }

  static getIPOReadinessConfiguredCountries(geo?: string): string {
    return [this.GeoRelatedUrl, ApiEntity.IPOReadiness, 'settings', 'countries'].join(separator);
  }

  static getCountryStates(countryName: string): string {
    return [this.GeoRelatedUrl, ApiEntity.IPOReadiness, countryName, 'countrystates'].join(separator);
  }

  static updateIpoSettings(): string {
    return [this.GeoRelatedUrl, ApiEntity.IPOReadiness, 'settings '].join(separator);
  }

  static copyIpoSettings(): string {
    return [this.GeoRelatedUrl, ApiEntity.IPOReadiness, 'settings', 'copy'].join(separator);
  }

  static uploadContactPhoto(): string {
    return [this.GeoRelatedUrl, ApiEntity.IPOReadiness, 'photo', 'upload '].join(separator);
  }

  static uploadFile(): string {
    return [this.GeoRelatedUrl, ApiEntity.IPOReadiness, 'file', 'upload '].join(separator);
  }

  static getIpoSettingsEngagementType(memberFirm = 'us'): string {
    return [this.GeoRelatedUrl, ApiEntity.IPOReadiness, memberFirm, 'casetypedata'].join(separator);
  }

  static getIpoSettingTopics(engagementTypeId: number): string {
    return [this.GeoRelatedUrl, ApiEntity.IPOReadiness, engagementTypeId, 'topics'].join(separator);
  }

  static getStatisticsReportFile(apiUrl: string): string {
    return [apiUrl, ApiEntity.Report, ApiEntity.Case, 'statistics'].join(separator);
  }

  static sendConsolidatedReportFile(countryCode: string, apiUrl: string): string {
    return [apiUrl, ApiEntity.Report, ApiEntity.IPOReadinessReport, countryCode, 'excel'].join(separator);
  }

  static getIPOReadinessFeedbackReport(apiUrl: string): string {
    return [apiUrl, ApiEntity.Report, ApiEntity.IPOReadinessFeedback].join(separator);
  }

  static getCaseTypeModuleQuestionnaire({
    caseTypeId,
    moduleType,
    moduleUID,
    questionnaireUId
  }: {
    caseTypeId: number | string;
    moduleType: ModuleType;
    moduleUID: string;
    questionnaireUId: string;
  }) {
    const apiSubEntity = API_SUB_ENTITY_FOR_EXPORT_CASE_TYPE_MODULE_QUESTIONNAIRE[moduleType];

    if (!apiSubEntity) return '';

    return [
      this.GeoRelatedUrl,
      ApiEntity.CaseType,
      caseTypeId,
      apiSubEntity,
      moduleUID,
      'export',
      'questionnaire',
      questionnaireUId
    ].join(separator);
  }

  // ADMIN

  private static get adminApiUrl() {
    return [this.GeoRelatedUrl, ApiEntity.Admin].join(separator);
  }

  static getAdminList() {
    return [this.adminApiUrl, 'all'].join(separator);
  }

  static getOPMAdminList() {
    return [this.adminApiUrl, 'search'].join(separator);
  }

  static getContentAdmins(): string {
    return [this.adminApiUrl, 'contentAdmins'].join(separator);
  }

  static updateAdminRole() {
    return [this.adminApiUrl, 'role'].join(separator);
  }

  static addAdmins() {
    return this.adminApiUrl;
  }

  static deleteAdmin() {
    return this.adminApiUrl;
  }

  // SELF_ASSESS

  private static get selfAssessApiUrl() {
    return [this.GeoRelatedUrl, ApiEntity.SELF_ASSESS].join(separator);
  }

  private static get selfAssessTypesApiUrl() {
    return [this.selfAssessApiUrl, 'types'].join(separator);
  }

  static selfAssessTypesGetList() {
    return this.selfAssessTypesApiUrl;
  }

  static selfAssessTypesCreateType() {
    return this.selfAssessTypesApiUrl;
  }

  static selfAssessTypesUpdateType() {
    return this.selfAssessTypesApiUrl;
  }

  static selfAssessTypesDeleteType(id: SelfAssessTypeDTO['Id']) {
    return [this.selfAssessTypesApiUrl, id].join(separator);
  }

  static selfAssessTypesGetTypeSettings(id: SelfAssessTypeDTO['Id']) {
    return [this.selfAssessTypesApiUrl, id, 'settings'].join(separator);
  }

  static selfAssessTypesUpdateTypeSettings(id: SelfAssessTypeDTO['Id']) {
    return [this.selfAssessTypesApiUrl, id, 'settings'].join(separator);
  }

  static selfAssessTypesCopyTypeSettings(id: SelfAssessTypeDTO['Id']) {
    return [this.selfAssessTypesApiUrl, id, 'settings', 'copy'].join(separator);
  }

  static selfAssessTypesGetCountries() {
    return [this.selfAssessApiUrl, 'countries'].join(separator);
  }

  static selfAssessTypesGetCountryStates(countryCode: SelfAssessCountryDTO['Code']) {
    return [this.selfAssessApiUrl, 'countries', countryCode, 'states'].join(separator);
  }

  static selfAssessTypesGetCountryCaseTypes({
    isIPO,
    countryCode
  }: {
    isIPO: boolean;
    countryCode: SelfAssessCountryDTO['Code'];
  }) {
    return [this.selfAssessApiUrl, ...(isIPO ? ['ipo'] : []), 'countries', countryCode, 'casetypedata'].join(separator);
  }

  static selfAssessTypesGetCaseTypePrimarySectors(caseTypeId: CaseTypeDataDto['CaseTypeId']) {
    return [this.selfAssessApiUrl, 'casetypes', caseTypeId, 'primarySectors'].join(separator);
  }

  static selfAssessTypesGetTypeCountries(typeId: SelfAssessTypeDTO['Id']) {
    return [this.selfAssessApiUrl, 'types', typeId, 'countries'].join(separator);
  }

  static selfAssessTypesGetGeo({
    typeId,
    countryCode
  }: {
    typeId: SelfAssessTypeDTO['Id'];
    countryCode: SelfAssessCountryDTO['Code'];
  }) {
    return [this.selfAssessApiUrl, 'types', typeId, 'countries', countryCode, 'geo'].join(separator);
  }
}
