import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../../constants';
import { useGlobalFileScanListener } from '../../../hooks/useFileScanListener';
import { AutoLogout } from '../../auto-logout/AutoLogout';
import UserInfoDropdown from '../../user-info-dropdown/user-info-dropdown';
import { ThemedSelect } from './../../../../components/ThemedSelect/ThemedSelect';
import { useServiceAreaDropdown, selectOptions } from './hooks/useServiceAreaDropdown';

import './header.scss';

const Header: FC = () => {
  const { me, isAuthorized } = useGlobalFileScanListener();
  const isLoggedIn = !!me && isAuthorized;

  const { serviceArea, handleChange: handleAreaChange, isPractitionerRoute } = useServiceAreaDropdown(isLoggedIn);

  return (
    <header id="header" className="header d-flex align-items-center justify-content-between">
      <div className="header__content d-flex align-items-center">
        <Link to={ROUTES.landing} className="d-flex justify-content-start align-items-center header__logos">
          <img src="./images/logo.svg" alt="Deloitte Logo" />
          <div className="header__divider ms-3 me-3" />
          <figure className="omnia-logo">
            <img src="./images/omnia-logo.svg" alt="OMNIA" />
          </figure>
          <div className="header__divider ms-3 me-3" />
          <div className="header__text">Assurance Navigator</div>
        </Link>
      </div>
      <div className="d-flex">
        {isLoggedIn && isPractitionerRoute && (
          <div className="header__area-select">
            <ThemedSelect
              isSearchable={false}
              value={serviceArea}
              onChange={handleAreaChange}
              options={selectOptions}
              size="medium"
              isTransparent
              withCheckmark
            />
          </div>
        )}
        {isLoggedIn && (
          <div className="d-flex">
            <UserInfoDropdown user={me} />
            <AutoLogout />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
