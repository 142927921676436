import axios, { AxiosPromise } from 'axios';
import {
  CaseTypeDataDto,
  SaveModuleQuestionnaireStepScoresDTO,
  SaveModuleQuestionnaireStepScoresReturnDTO,
  SelectDictionaryItem
} from '../../interface/interfaces';
import { ModuleType } from '../../models/enums.bl';
import { CaseTypeDisclaimerDto } from '../../models/models.dl';
import ApiUrlsService, { API_SUB_ENTITY_FOR_EXPORT_CASE_TYPE_MODULE_QUESTIONNAIRE } from '../bl/api-urls.service';

export default class CaseTypeDetailService {
  static getCaseTypeData(id: number): AxiosPromise<CaseTypeDataDto> {
    return axios.get(`${ApiUrlsService.getCaseTypeData()}/${id}`);
  }

  static updateDisclaimer(id: number, isEnabled: boolean, title: string, description: string): AxiosPromise<any> {
    return axios.post(`${ApiUrlsService.updateDisclaimer()}`, {
      caseTypeId: id,
      isEnabled,
      title,
      text: description
    });
  }

  static getDisclaimerData(id: string | number): AxiosPromise<CaseTypeDisclaimerDto> {
    return axios.get(ApiUrlsService.getDisclaimer(id));
  }

  static getStepandQuestionnaire(id: string, mId: any): AxiosPromise<any> {
    return axios.get(`${ApiUrlsService.getStepandQuestionnaire()}/${id}/module/${mId}`);
  }

  static getModuleScore(id: string): AxiosPromise<any> {
    return axios.get(`${ApiUrlsService.getModuleScore()}/${id}`);
  }

  static saveQuestionaire(payLoad: any): AxiosPromise<any> {
    return axios.post(ApiUrlsService.saveQuestionaire(), payLoad);
  }

  static saveModuleQuestionnaireStepScores(
    payLoad: SaveModuleQuestionnaireStepScoresDTO
  ): Promise<SaveModuleQuestionnaireStepScoresReturnDTO> {
    return axios
      .post<SaveModuleQuestionnaireStepScoresReturnDTO>(ApiUrlsService.saveModuleQuestionnaireStepScores(), payLoad)
      .then(({ data }) => {
        if (data.ErrorMessage) {
          throw new Error(data.ErrorMessage);
        }
        return data;
      });
  }

  static publishCaseType(id: number): AxiosPromise<any> {
    return axios.get(`${ApiUrlsService.publishCaseType(id)}`);
  }

  static unpublishCaseType(id: string): AxiosPromise<any> {
    return axios.post(`${ApiUrlsService.unpublishCaseType(id)}`);
  }

  static saveModuleOrder(payLoad: any): AxiosPromise<any> {
    return axios.post(ApiUrlsService.saveModuleOrder(), payLoad);
  }

  static getQuestionnaireTasks(id: number): AxiosPromise<any> {
    return axios.get(`${ApiUrlsService.getQuestionnaireTasks()}/${id}`);
  }

  static saveModuleScore(payLoad: any): AxiosPromise<any> {
    return axios.post(ApiUrlsService.saveModuleScore(), payLoad);
  }

  static getQuestionnaireScore(id: any): AxiosPromise<any> {
    return axios.get(`${ApiUrlsService.getQuestionnaireScore()}/${id}`);
  }

  static saveQuestionnaireGlossary(payLoad: any): AxiosPromise<any> {
    return axios.post(ApiUrlsService.saveQuestionnaireGlossary(), payLoad);
  }

  static saveQuestionnaireScore(payLoad: any): AxiosPromise<any> {
    return axios.post(ApiUrlsService.saveQuestionnaireScore(), payLoad);
  }

  static saveModuleColorConfig(payLoad: any): AxiosPromise<any> {
    return axios.post(ApiUrlsService.saveModuleColorConfig(), payLoad);
  }

  static getQuestionTypes(moduleUID: string): AxiosPromise<SelectDictionaryItem[]> {
    return axios.get(`${ApiUrlsService.getQuestionTypes()}?ModuleUID=${moduleUID}`);
  }

  static deleteCaseType(caseTypeId: number): AxiosPromise<any> {
    return axios.delete(`${ApiUrlsService.deleteCaseType(caseTypeId)}`);
  }

  static deleteCase(uid: string): AxiosPromise<any> {
    return axios.post(`${ApiUrlsService.deleteCase(uid)}`);
  }

  static hardDeleteCase(uid: string): AxiosPromise<any> {
    return axios.delete(`${ApiUrlsService.hardDeleteCase(uid)}`);
  }

  static restoreCase(uid: string): AxiosPromise<any> {
    return axios.post(`${ApiUrlsService.restoreCase(uid)}`);
  }

  static getCaseTeamMembers(uid: string): AxiosPromise<any> {
    return axios.get(ApiUrlsService.getCaseTeamMembers(uid));
  }

  static postCaseTeamMembers(members: any): AxiosPromise<any> {
    return axios.post(ApiUrlsService.postCaseTeamMembers(), members);
  }

  static getScoreCardTemplate(): AxiosPromise<Blob> {
    return axios.get(ApiUrlsService.getScoreCardTemplate(), { responseType: 'blob' });
  }

  static getRoadmapDrivers(): AxiosPromise<any> {
    return axios.get(`${ApiUrlsService.getRoadmapDrivers()}`);
  }

  static getRoadmapMaturityLevels(): AxiosPromise<any> {
    return axios.get(`${ApiUrlsService.getRoadmapMaturityLevels()}`);
  }

  static getRoadmapTemplate(moduleUID: string): AxiosPromise<Blob> {
    return axios.get(ApiUrlsService.getRoadmapTemplate(moduleUID), { responseType: 'blob' });
  }

  static getSummaryReportTemplate(): AxiosPromise<Blob> {
    return axios.get(ApiUrlsService.getSummaryReportTemplate(), { responseType: 'blob' });
  }

  static getSAPReportTemplate(): AxiosPromise<Blob> {
    return axios.get(ApiUrlsService.getSAPReportTemplate(), { responseType: 'blob' });
  }

  static getIpoReadinessReportTemplate(): AxiosPromise<Blob> {
    return axios.get(ApiUrlsService.getIpoReadinessReportTemplate(), { responseType: 'blob' });
  }

  static getSelfAssessGeneralInfoReportTemplate(): AxiosPromise<Blob> {
    return axios.get(ApiUrlsService.getSelfAssessGeneralInfoReportTemplate(), { responseType: 'blob' });
  }

  static canExportCaseTypeModuleQuestionnaire(moduleType: ModuleType) {
    return !!API_SUB_ENTITY_FOR_EXPORT_CASE_TYPE_MODULE_QUESTIONNAIRE[moduleType];
  }

  static async getCaseTypeModuleQuestionnaire(
    data: Parameters<typeof ApiUrlsService.getCaseTypeModuleQuestionnaire>[0]
  ) {
    return axios.get<Blob>(ApiUrlsService.getCaseTypeModuleQuestionnaire(data), {
      responseType: 'blob'
    });
  }
}
