import axios, { AxiosPromise } from 'axios';
import { TemplateFile } from './../../models/models.bl';
import { CaseTypeModuleImportResponseDto } from './../../models/models.dl';
import ApiUrlsService from './api-urls.service';
import { ModuleType } from '../../models/enums.bl';

export default class FilesService {
  static getFilesList(moduleUID: string): AxiosPromise<{ IsCaseTypePublishable: boolean; Files: TemplateFile[] }> {
    return axios.get(ApiUrlsService.getFilesList(moduleUID));
  }

  static downloadTemplate(moduleUID: string, fileId: number): AxiosPromise<Blob> {
    return axios.get(ApiUrlsService.downloadFile(moduleUID, fileId), { responseType: 'blob' });
  }

  static uploadFiles(files: File[], moduleUID: string, caseTypeId = 0): AxiosPromise<any> {
    const formData = new FormData();

    files.forEach((file) => formData.append('Files', file, file.name));

    return axios.post(ApiUrlsService.uploadFiles(caseTypeId, moduleUID), formData);
  }

  static removeFile(moduleUID: string, fileId: number, caseTypeId = 0): AxiosPromise<any> {
    return axios.delete(`${ApiUrlsService.deleteFile(caseTypeId, moduleUID)}/${fileId}`);
  }

  private static getCaseTypeModuleImportFileApiUrl(
    moduleType: ModuleType
  ): ((caseTypeId: number, moduleUID: string) => string) | null {
    // INFO: if ApiUrlsService.someGetUrlMethod is not arrow function
    // make return ApiUrlsService.someGetUrlMethod.bind(ApiUrlsService)
    // to prevent this === undefined after call
    switch (moduleType) {
      case ModuleType.ROADMAP:
        return ApiUrlsService.uploadRoadmapFiles.bind(ApiUrlsService);
      case ModuleType.SCORECARD:
        return ApiUrlsService.uploadScorecardFiles.bind(ApiUrlsService);
      case ModuleType.SUMMARY:
        return ApiUrlsService.uploadReportFile.bind(ApiUrlsService);
      case ModuleType.SAP:
        return ApiUrlsService.uploadSapReportFiles.bind(ApiUrlsService);
      case ModuleType.IPO_SCANNER:
        return ApiUrlsService.uploadIPOReportFiles.bind(ApiUrlsService);
      case ModuleType.SELF_ASSESS_GENERAL_INFO:
        return ApiUrlsService.uploadSelfAssessGeneralInfoReportFiles.bind(ApiUrlsService);
      default:
        return null;
    }
  }

  static canCaseTypeModuleImportFile(moduleType: ModuleType) {
    return !!this.getCaseTypeModuleImportFileApiUrl(moduleType);
  }

  static importCaseTypeModuleFile({
    caseTypeId,
    moduleUID,
    moduleType,
    file
  }: {
    file: File;
    moduleUID: string;
    moduleType: ModuleType;
    caseTypeId: number;
  }): AxiosPromise<CaseTypeModuleImportResponseDto> | null {
    const getApiUrl = this.getCaseTypeModuleImportFileApiUrl(moduleType);
    if (!getApiUrl) return null;

    const formData = new FormData();
    formData.append('Files', file, file.name);

    return axios.post(getApiUrl(caseTypeId, moduleUID), formData);
  }
}
