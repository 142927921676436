import React, { FC } from 'react';
import { CommonIconPropsI } from './common-icon-props.type';

const EmailIcon: FC<CommonIconPropsI> = ({ size = 24, ...props }) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 6C20.7761 6 21 6.22386 21 6.5V17.5C21 17.7455 20.8231 17.9496 20.5899 17.9919L20.5 18H3.5C3.22386 18 3 17.7761 3 17.5V6.5C3 6.22386 3.22386 6 3.5 6H20.5ZM14.192 12.452L12.3037 13.8972C12.1244 14.0343 11.8756 14.0343 11.6963 13.8972L9.807 12.453L4.28653 17H19.7135L14.192 12.452ZM20 8.011L15.007 11.829L20 15.941V8.011ZM4 8.012V15.94L8.992 11.829L4 8.012ZM19.675 7H4.324L12 12.8706L19.675 7Z"
        fill="black"
      />
    </svg>
  );
};

export default EmailIcon;
