import React, { FC } from 'react';
import { connect } from 'react-redux';
import * as Models from '../../shared.models';
import Services from './../../../shared/shared.services';
import Dropdown from 'react-bootstrap/Dropdown';
import { ROUTES } from '../../../shared/constants';
import { MsalAuthService } from '../../../msal-auth';

import './user-info-dropdown.scss';
import {
  areaUrls,
  getPrevServiceAreaFromLocalStorage,
  getRouteWithServiceArea,
  mapUrlToArea,
  serviceAreaValue,
  setServiceAreaToLocalStorage
} from '../layout/landing/service-area-blocks/ServiceArea.helpers';
import HeadphonesIcon from '../../../assets/icons/HeadphonesIcon';
import EmailIcon from '../../../assets/icons/EmailIcon';
import RouteTreeIcon from '../../../assets/icons/RouteTreeIcon';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useAdminToolTelemetry } from '../../../pages/casetype-dashboard/hooks/useAdminToolTelemetry';

interface IUserInfoDropdownUProps {
  user: Models.DM.UserGetDto;
  store: any;
}

const emailAssuranceSupport = 'assurancesupport@deloitte.com';

const onClickTool = (isAdminTool: boolean) => {
  localStorage.setItem('fromAdminAccess', `${isAdminTool}`);
  const serviceArea = serviceAreaValue();
  if (!serviceArea) {
    setServiceAreaToLocalStorage(getPrevServiceAreaFromLocalStorage() || mapUrlToArea[areaUrls[0]]);
  }
};

const AdminToolLink: FC = () => {
  const { trackNavigateToAdminTool } = useAdminToolTelemetry();

  return (
    <span
      onClick={() => {
        trackNavigateToAdminTool();
        onClickTool(true);
      }}
    >
      <a href={ROUTES.casetypeDashboard}>Admin Tool</a>
    </span>
  );
};

class UserInfoDropdown extends React.Component<IUserInfoDropdownUProps & RouteComponentProps> {
  getAbbreviation(user: Models.DM.UserGetDto): string {
    if (!user) {
      return '';
    }

    const firstName: string = `${user.FirstName}`.trim().split('')[0];
    const lastName: string = `${user.LastName}`.trim().split('')[0];

    return `${firstName}${lastName}`.toLocaleUpperCase();
  }

  getIsFromAdminAccess() {
    return (
      localStorage.getItem('fromAdminAccess') === 'true' && this.props.history.location.pathname !== ROUTES.landing
    );
  }

  handleAdminClick() {
    localStorage.setItem('fromAdminAccess', 'true');
    const serviceArea = serviceAreaValue();
    const prevServiceArea = getPrevServiceAreaFromLocalStorage();
    if (!serviceArea) {
      setServiceAreaToLocalStorage(prevServiceArea || mapUrlToArea[areaUrls[0]]);
    }
  }

  render(): React.ReactNode {
    const isFromAdminAccess = this.getIsFromAdminAccess();
    return !this.props.user ? null : (
      <div className="user-info">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-custom-components">
            <div className="d-flex align-items-center">
              <div className="user-picture">{this.getAbbreviation(this.props.user)}</div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu align="end">
            <div className="profile-info">
              <div className="main-info d-flex align-items-center">
                <div className="user-picture">{this.getAbbreviation(this.props.user)}</div>
                <div>
                  <div className="name">
                    {this.props.user.FirstName} {this.props.user.LastName}
                  </div>
                  <div className="email">{this.props.user.EmailAddress}</div>
                  {Services.BL.AuthorizationService.isAdmin() && (
                    <div className="admin-tool mt-3">
                      {isFromAdminAccess && (
                        <span className="dashboard-icon">
                          <svg
                            width="16"
                            height="15"
                            viewBox="0 0 16 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.500018 13C0.776161 13 1.00002 13.2239 1.00002 13.5V14.5C1.00002 14.7761 0.776161 15 0.500018 15C0.223876 15 1.84154e-05 14.7761 1.84154e-05 14.5V13.5C1.84154e-05 13.2239 0.223876 13 0.500018 13ZM3.50002 11C3.77616 11 4.00002 11.2239 4.00002 11.5V14.5C4.00002 14.7761 3.77616 15 3.50002 15C3.22388 15 3.00002 14.7761 3.00002 14.5V11.5C3.00002 11.2239 3.22388 11 3.50002 11ZM6.50002 9C6.77616 9 7.00002 9.22386 7.00002 9.5V14.5C7.00002 14.7761 6.77616 15 6.50002 15C6.22388 15 6.00002 14.7761 6.00002 14.5V9.5C6.00002 9.22386 6.22388 9 6.50002 9ZM9.50002 9C9.77616 9 10 9.22386 10 9.5V14.5C10 14.7761 9.77616 15 9.50002 15C9.22388 15 9.00002 14.7761 9.00002 14.5V9.5C9.00002 9.22386 9.22388 9 9.50002 9ZM12.5 7C12.7762 7 13 7.22386 13 7.5V14.5C13 14.7761 12.7762 15 12.5 15C12.2239 15 12 14.7761 12 14.5V7.5C12 7.22386 12.2239 7 12.5 7ZM15.5 7C15.7762 7 16 7.22386 16 7.5V14.5C16 14.7761 15.7762 15 15.5 15C15.2239 15 15 14.7761 15 14.5V7.5C15 7.22386 15.2239 7 15.5 7ZM11.5 0L15.5428 0.00182295L15.6282 0.0166083L15.6911 0.0377922L15.767 0.077188L15.8222 0.117594L15.8824 0.177876L15.9112 0.21534L15.9533 0.288604L15.9834 0.371861L15.9979 0.454214L16 0.5V4.5C16 4.77614 15.7762 5 15.5 5C15.2546 5 15.0504 4.82312 15.0081 4.58988L15 4.5L15 1.706L9.85357 6.85355C9.77856 6.92857 9.68216 6.97728 9.57871 6.99377L9.50002 7H5.67541L0.812366 10.8904C0.596735 11.0629 0.282089 11.028 0.109584 10.8123C-0.0437536 10.6207 -0.0331678 10.3508 0.122522 10.172L0.187671 10.1096L5.18767 6.10957C5.25416 6.05637 5.33275 6.02134 5.41578 6.00715L5.50002 6H9.29291L14.292 1H11.5C11.2546 1 11.0504 0.823125 11.0081 0.589876L11 0.5C11 0.223858 11.2239 0 11.5 0Z"
                              fill="black"
                            />{' '}
                          </svg>
                          <span onClick={() => onClickTool(false)}>
                            <a href={getRouteWithServiceArea(ROUTES.dashboard)}>Engagement Dashboard</a>
                          </span>
                        </span>
                      )}
                      {!isFromAdminAccess && (
                        <>
                          <RouteTreeIcon />
                          <AdminToolLink />
                        </>
                      )}
                    </div>
                  )}
                  <div className="admin-tool mt-3">
                    <span className="contact-support-icn">
                      <HeadphonesIcon />
                    </span>
                    <span>
                      <a href={(window as any).ApiConfig.CONTACT_SUPPORT} target="_blank" rel="noreferrer">
                        Create a Support Request
                      </a>
                    </span>
                  </div>
                  <div className="admin-tool mt-3">
                    <span className="contact-support-icn">
                      <EmailIcon />
                    </span>
                    <span>
                      <a href={`mailto:${emailAssuranceSupport}`} target="_blank" rel="noreferrer">
                        Email Assurance Support
                      </a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="button-wrap">
                <button
                  className="btn deloitte-primary-btn btn-transparent-blue ms-auto"
                  type="button"
                  onClick={this.logout}
                >
                  Log out
                </button>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  logout = async () => {
    await Services.DL.UserDataService.logout();
    Services.BL.AuthenticationService.logOut();
    MsalAuthService.logout();
  };
}

export default withRouter(
  connect(
    (state) => ({ store: state }),
    (dispatch) => ({})
  )(UserInfoDropdown)
);
