import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ProtectedRoute from './shared/components/protected-route/protected-route';
import { ROUTES, withServiceArea } from './shared/constants';
import Services from './shared/shared.services';

const Landing = lazy(() => import('./shared/components/layout/landing/landing'));
const MaintenancePage = lazy(() => import('./shared/components/layout/MaintenancePage/MaintenancePage'));
const MsalAuthCallbackPage = lazy(() => import('./msal-auth/msal-auth.callback-page'));
const TermsofUse = lazy(() => import('./pages/TermsofUse/TermsofUse'));
const UnAuthorized = lazy(() => import('./pages/un-authorized/un-authorized'));
const AdminUnAuthorized = lazy(() => import('./pages/admin-unauthorized/admin-unauthorized'));
const Dashboard = lazy(() => import('./pages/dashboard/dashboard'));
const CaseRecycleBin = lazy(() => import('./pages/CaseRecycleBin/CaseRecycleBin'));
const CreateCase = lazy(() => import('./pages/create-case/create-case'));
const WorkFlowList = lazy(() => import('./pages/workflowlist/WorkFlowList'));
const WorkFlowReports = lazy(() => import('./pages/workflowReports/workflowReports'));
const Questionnaire = lazy(() => import('./pages/Questionnaire/Questionnaire'));
const FgAnalysis = lazy(() => import('./pages/fg-analysis/fg-analysis'));
const ScoreCardReport = lazy(() => import('./pages/scorecardReport/scorecardReport'));
const IllustrativeFIRreport = lazy(() => import('./pages/illustrativeFIRreport/illustrativeFIRreport'));
const IPORoadmapreport = lazy(() => import('./pages/ipoRoadmap/ipoRoadmap'));
const BusinessProcessList = lazy(() => import('./pages/BusinessProcessList/BusinessProcessList'));
const BusinessProcessInputFlow = lazy(() => import('./pages/BusinessProcessInputFlow/BusinessProcessInputFlow'));
const CasetypeDashboard = lazy(() => import('./pages/casetype-dashboard/casetype-dashboard'));
const CreateCaseType = lazy(() => import('./pages/Admin/CreateCaseType/createCasetype'));
const CaseTypeDetails = lazy(() => import('./pages/CaseTypeDetails/CaseTypeDetails'));
const ViewModule = lazy(() => import('./pages/view-module/view-module'));
const RoadmapModule = lazy(() => import('./pages/ipoRoadmap/RoadmapModule'));
const Scorecardreport = lazy(() => import('./pages/NewScoreCardReport/Scorecardreport'));
const Roadmapreport = lazy(() => import('./pages/ipoRoadmap/roadmapReport'));
const ScorecardSnapshot = lazy(() => import('./pages/NewScoreCardSnapshot/ScorecardSnapshot'));
const ScorecardSnapshotOne = lazy(() => import('./pages/scorecardSnapshotReport/ScorecardSnapshotOne'));
const v2IlustrativeFirpReport = lazy(() => import('./pages/v2-IllustrativeFirpReport/V2IllustrativeFirpReport'));
const ManageVisibility = lazy(() => import('./pages/Manage-visibility/ManageVisibility'));
const FinanceMaturityModel = lazy(() => import('./pages/fmm-report/fmm-report'));
const FgAnalysisReport = lazy(() => import('./pages/V2-Fg-Analysis/FgAnalysisReport'));
const Settings = lazy(() => import('./pages/Settings/Settings'));
const SelfAssessmentAssets = lazy(() => import('./pages/SelfAssessmentAssets/SelfAssessmentAssetsContainer'));
const UserAdministration = lazy(() => import('./pages/Admin/user-administration/user-administration'));
const SapReport = lazy(() => import('./pages/SapReport/SapReport'));

export default () => {
  return (
    <Suspense fallback={<></>}>
      <Switch>
        <Route exact path={ROUTES.landing} component={Landing} />
        <Route exact path={ROUTES.maintenance} component={MaintenancePage} />
        <Route exact={true} path={ROUTES.authCallback} component={MsalAuthCallbackPage} />
        <Route exact={true} path={ROUTES.termsofUse} component={TermsofUse} />
        <Route exact={true} path={ROUTES.unAuthorized} component={UnAuthorized} />
        <Route exact={true} path={ROUTES.adminUnAuthorized} component={AdminUnAuthorized} />
        <ProtectedRoute
          exact
          path={ROUTES.dashboard}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={Dashboard}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.dashboard)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={Dashboard}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.caseRecycleBin)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={CaseRecycleBin}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.createCase)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={CreateCase}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.editCase)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={CreateCase}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.workFlows)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={WorkFlowList}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.workFlowReports)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={WorkFlowReports}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.questionnaire)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={Questionnaire}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.fgAnalysis)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={FgAnalysis}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.scorecardReport)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={ScoreCardReport}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.ipoSapReport)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={SapReport}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.illustrativeReport)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={IllustrativeFIRreport}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.iporoadmapReport)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={IPORoadmapreport}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.businessProcessList)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={BusinessProcessList}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.BusinessProcessInputFlow)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={BusinessProcessInputFlow}
        />
        <ProtectedRoute
          exact
          path={ROUTES.casetypeDashboard}
          role="admin"
          accessPredicate={Services.BL.AuthorizationService.isAdmin}
          component={CasetypeDashboard}
        />
        <ProtectedRoute
          exact
          path={ROUTES.AdminCreateCaseType}
          role="admin"
          accessPredicate={Services.BL.AuthorizationService.isAdmin}
          component={CreateCaseType}
        />
        <ProtectedRoute
          exact
          path={ROUTES.AdminCopyCasetype}
          role="admin"
          accessPredicate={Services.BL.AuthorizationService.isAdmin}
          component={CreateCaseType}
        />
        <ProtectedRoute
          exact
          path={ROUTES.AdminEditCaseType}
          role="admin"
          accessPredicate={Services.BL.AuthorizationService.isAdmin}
          component={CreateCaseType}
        />
        <ProtectedRoute
          exact
          path={ROUTES.casetypeDetails}
          role="admin"
          accessPredicate={Services.BL.AuthorizationService.isAdmin}
          component={CaseTypeDetails}
        />
        <ProtectedRoute
          exact
          path={ROUTES.viewModule}
          role="admin"
          accessPredicate={Services.BL.AuthorizationService.isAdmin}
          component={ViewModule}
        />
        <ProtectedRoute
          exact
          path={ROUTES.roadmapModule}
          role="admin"
          accessPredicate={Services.BL.AuthorizationService.isAdmin}
          component={RoadmapModule}
        />
        <ProtectedRoute
          exact
          path={ROUTES.settings}
          role="admin"
          component={Settings}
          accessPredicate={Services.BL.AuthorizationService.isGlobalAdmin}
          redirectUrl={ROUTES.casetypeDashboard}
        />
        <ProtectedRoute
          exact
          path={ROUTES.SelfAssessmentAssets}
          role="admin"
          component={SelfAssessmentAssets}
          accessPredicate={Services.BL.AuthorizationService.isGlobalAdmin}
          redirectUrl={ROUTES.casetypeDashboard}
        />
        <ProtectedRoute
          exact
          path={ROUTES.UserAdministration}
          role="admin"
          component={UserAdministration}
          accessPredicate={Services.BL.AuthorizationService.isGlobalAdmin}
          redirectUrl={ROUTES.casetypeDashboard}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.ipoScorecardreport)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={Scorecardreport}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.newIpoRoadmapreport)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={Roadmapreport}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.fgAnalysisReport)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={FgAnalysisReport}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.V2IlustrativeFirpReport)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={v2IlustrativeFirpReport}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.managevisibility)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={ManageVisibility}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.ipoScorecardsnapshot)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={ScorecardSnapshot}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.scorecardReportSnapshot)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={ScorecardSnapshotOne}
        />
        <ProtectedRoute
          exact
          path={withServiceArea(ROUTES.fmmReport)}
          role="practitioner"
          accessPredicate={Services.BL.AuthorizationService.isPractitioner}
          component={FinanceMaturityModel}
        />
        <Redirect from="*" to={ROUTES.home} />
      </Switch>
    </Suspense>
  );
};
