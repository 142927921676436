import { ModuleType } from '../models/enums.bl';

// INFO: for summary report ModuleType can be SummaryReport in BE response
export const checkIsSummaryReportModule = (moduleType?: string | ModuleType) =>
  moduleType === ModuleType.SUMMARY || moduleType === ModuleType.SUMMARY.replace(/\s/g, '');

export const checkIsSAPReportModule = (moduleType?: string | ModuleType) =>
  moduleType === ModuleType.SAP || moduleType?.toLowerCase() === ModuleType.SAP.replace(/\s/g, '').toLowerCase();

export const checkIsIPOScannerModule = (moduleType?: string | ModuleType) =>
  moduleType === ModuleType.IPO_SCANNER ||
  moduleType?.toLowerCase() === ModuleType.IPO_SCANNER.replace(/\s/g, '').toLowerCase();

export const checkIsSelfAssessGeneralInfoModule = (moduleType?: string | ModuleType) =>
  moduleType === ModuleType.SELF_ASSESS_GENERAL_INFO ||
  moduleType?.toLowerCase() === ModuleType.SELF_ASSESS_GENERAL_INFO.replace(/\s/g, '').toLowerCase();

export const checkIsScoreCardModule = (moduleType?: string | ModuleType) =>
  moduleType === ModuleType.SCORECARD ||
  moduleType?.toLowerCase() === ModuleType.SCORECARD.replace(' ', '').toLowerCase();

export const checkIsRoadMapModule = (moduleType?: string | ModuleType) =>
  moduleType === ModuleType.ROADMAP || moduleType?.toLowerCase() === ModuleType.ROADMAP.replace(' ', '').toLowerCase();
