export const ROUTES = {
  landing: '/',
  home: '/',
  login: '/login',
  settings: '/settings',
  authCallback: '/authcallback',
  termsofUse: '/TermsofUse',
  unAuthorized: '/unauthorized',
  adminUnAuthorized: '/admin-unauthorized',
  clientEventReadiness: '/CER',
  dashboard: '/case/dashboard',
  caseRecycleBin: '/case/recycle-bin',
  createCase: '/case/new',
  editCase: '/case/edit/:workflowid',
  editCaseRedirect: '/case/edit/',
  caseDetails: '/casedetails',
  workFlows: '/case/:workflowid',
  workFlowsForCrump: '/case/',
  workFlowReports: '/questionnaire',
  fgAnalysis: '/report/fga',
  scorecardReport: '/report/scorecard',
  scorecardReportSnapshot: '/report/scorecardsnapshot',
  module: '/module/',
  Scorecardreport: '/scorecardreport',
  ipoScorecardreport: '/case/:workflowid/module/:moduleUID/scorecardreport',
  ScorecardSnapshot: '/scorecardsnapshot',
  ipoSapReport: '/case/:workflowid/module/:moduleUID/sapreport',
  ipoScorecardsnapshot: '/case/:workflowid/module/:moduleUID/scorecardsnapshot',
  roadmapReport: '/roadmapreport',
  newIpoRoadmapreport: '/case/:caseUID/module/:moduleUID/roadmapreport',
  illustrativeReport: '/report/illustrative',
  iporoadmapReport: '/report/roadmap',
  businessProcessList: '/business-process-list',
  BusinessProcessInputFlow: '/business-process-inputs',
  casetypeDashboard: '/casetype/dashboard',
  casetypeDetails: '/casetype/:caseId',
  casetypeDetailsRedirect: '/casetype/',
  AdminCreateCaseType: '/casetype/new',
  AdminCopyCasetype: '/casetype/new/:copyfromcasetypeId',
  AdminEditCaseType: '/casetype/edit/:caseTypeId/:caseTypeCategoryId',
  AdminEditCaseTypeRedirect: '/casetype/edit/',
  viewModule: '/casetype/view-module',
  questionnaireForCrump: '/questionnaire/',
  questionnaire: '/case/:caseUID/module/:moduleUID/questionnaire/:questionnaireUID',
  roadmapModule: '/roadmap/module/:caseTypeId/:moduleId/:questionnaireUID',
  roadmapModuleRedirect: '/roadmap/module/',
  fgAnalysisReport: '/case/:workflowid/module/:moduleUID/fgareport',
  V2IlustrativeFirpReport: '/case/:workflowid/module/:moduleUID/illustrativeFirpReport',
  fgareport: '/fgareport',
  illustrativeFirpReportV2: '/illustrativeFirpReport',
  managevisibility: '/case/:workflowid/managevisibility',
  managevisibilityforcrump: '/managevisibility',
  maintenance: '/maintenance',
  fmm: '/fmm-report',
  fmmReport: '/case/:workflowid/module/:moduleUID/fmm-report',
  UserAdministration: '/user-administration',
  SelfAssessmentAssets: '/self-assessment-assets'
} as const;

type RoutesT = typeof ROUTES;

export const withServiceArea = <T extends RoutesT[keyof RoutesT]>(route: T) => {
  return `/:serviceArea${route}` as const;
};
